html::after {
  content: '';
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: $color-white;
  animation: loaderAnim 0.7s linear infinite alternate forwards;
  transition: opacity 0.4s ease;
}

html.loaded::after {
  // animation-play-state: paused;
  animation: none;
  opacity: 0 !important;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}
