@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500&family=Raleway:wght@400;500&display=swap');

@import './variables.scss';
@import './base.scss';
@import './loader.scss';
@import './gallery2.scss';

.logo {
    max-width: 300px;

    @media (max-width: $breakpoint) {
        max-width: 30vw;
    }
  
    img {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
    }
}

.has-perspective {
    perspective: 1000px;
    perspective-origin: 50% 50%;
}

.headline {
  color: $color-white;
  font-family: 'Bodoni Moda';
  font-weight: 600;
  line-height: 1.1;
  max-width: 28ch;
  font-kerning: normal;
  font-size: calc(2.52vw + 20px)
}

.linkedin-button {  
    display: flex;   
    gap:10px;
    align-items: center;
    padding:10px;
    font-size: calc(.5vw + 16px);
    border-radius: 15px;        
    background-color: $color-white;
    color:$color-dark-blue;
    &:hover {
        background-color: $color-white;
        transform:scale(1.05);
        color: $color-dark-blue;
    }
    img {
        width:calc(.5vw + 22px);
    }    
}

.overlay {
    position: absolute;
    opacity: 0.9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(to left, transparent, $color-dark-blue);
}

.content {
    width: 100%;
    height: 100vh;
    overflow-y: scroll; 
    top:0;
    left:0;
    right:0;
    bottom:0;
    position: absolute;
    z-index: 10;
    color: $color-light-blue;
    opacity: 0;

    .loaded & {
        opacity: 1;
        transition: opacity 1s ease;
    }
    &__header {
        display:flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2vw;
        gap: 5vh;
        width: 100%;
        min-height: 100vh;

        @media (max-width: $breakpoint) {
            justify-content: start;
            gap: 12rem;
            padding: 6rem;
        }

    }
} 

.contact-details {
    //margin-top: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width:1000px) {
        flex-direction: column-reverse;
    align-items: flex-start;
    //gap: 30px;
    margin-top: 12rem;
    }
    @media (min-width: 900px) {
        //margin-top: 10rem;       

    }

    @media (min-width: 1200px) {
        //margin-top: 8rem;

    }  

    @media (min-width: 2000px) {
        margin-top: 0;
    }  

    h3 {
        margin-bottom: 3rem;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;

        @media (max-width: $breakpoint) {
            font-size: 19px;
            margin-bottom: 12px;
        }   
    }

    nav {
        margin: calc((7rem - 2rem) + 1.5rem) 0;
        
        @media (min-width: 701px) and (max-width: $breakpoint) {
            margin: 12rem 0;
        }

        @media (max-width: 700px) {
            margin: 12rem 0;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: start;
        align-items: center;

        @media (max-width: 700px) {
            gap: 15px;
            flex-direction: column;
            align-items: flex-start;
            margin:0;
        }
    }

    li {
        margin: 0;

        @media (max-width: $breakpoint) {
            margin: 2rem 0;
        }   

        span {
            //margin-left: 1.2rem;

            @media (max-width: $breakpoint) {
                //margin-left: 2rem;
            }   
        }
        svg {
            width: 46px;
            fill: currentColor;

            @media (max-width: $breakpoint) {
                width: 37px;
            }   
        }
    }

    h2 {
        text-transform: uppercase;
        letter-spacing: 5px;
        font-size: calc(14px + (16 - 14) * (100vw - 400px) / (800 - 400));
        
    }

    a {
        font-size: calc(18px + (20 - 18) * (100vw - 400px) / (800 - 400));
        display: flex;
        align-items: center;
        gap:15px;
    }

    address {     
        display: flex;
        align-items: center;
        text-align: left;

        @media (max-width: $breakpoint) {
            max-width: 35ch;
        }   

        a {
            font-size: 16px;
        }
  
        svg {
            width: 16px;   
            opacity: 0.5; 
            
            @media (max-width: $breakpoint) {
                width: 28px;
            }
        }

        span {
            margin-left: .8rem;

            @media (max-width: $breakpoint) {
                margin-left: 3rem;
            }
        }
    }
}