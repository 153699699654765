@import "https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500&family=Raleway:wght@400;500&display=swap";
* {
  margin: 0;
  padding: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  background: #5e6b7a;
  font-size: .520833vw;
  position: fixed;
  top: 0;
  left: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: Raleway, serif;
  font-size: 21px;
  overflow: hidden;
}

body:after {
  content: "";
  height: 300%;
  opacity: 0;
  pointer-events: none;
  width: 300%;
  will-change: transform;
  z-index: 3;
  background-image: url("grain.66325618.png");
  background-repeat: repeat;
  transition: opacity .4s;
  animation: 6s steps(10, end) infinite grain;
  position: fixed;
  top: -100%;
  left: -100%;
}

.loaded body:after {
  opacity: .03;
}

main {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

h1, h2, h3 {
  color: #fff;
  font-family: Bodoni Moda;
  font-weight: 600;
  line-height: 1;
}

img {
  width: 100%;
  display: block;
}

ul {
  list-style: none;
}

a {
  color: #bacfd7;
  outline: none;
  text-decoration: none;
  transition: all .4s;
}

a:hover, a:focus {
  color: #fff;
  outline: none;
}

svg {
  fill: currentColor;
}

@keyframes grain {
  0% {
    transform: translate(20%, -15%);
  }

  10% {
    transform: translate(-20%, -15%);
  }

  20% {
    transform: translate(20%, -5%);
  }

  30% {
    transform: translate(-20%, -5%);
  }

  40% {
    transform: translate(20%, 5%);
  }

  50% {
    transform: translate(-20%, 5%);
  }

  60% {
    transform: translate(20%, 15%);
  }

  70% {
    transform: translate(-20%, 15%);
  }

  80% {
    transform: translate(20%, 5%);
  }

  90% {
    transform: translate(-20%, 5%);
  }

  100% {
    transform: translate(20%, -5%);
  }
}

html:after {
  content: "";
  z-index: 1000;
  width: 60px;
  height: 60px;
  opacity: .4;
  background: #fff;
  border-radius: 50%;
  margin: -30px 0 0 -30px;
  transition: opacity .4s;
  animation: .7s linear infinite alternate forwards loaderAnim;
  position: fixed;
  top: 50%;
  left: 50%;
}

html.loaded:after {
  animation: none;
  opacity: 0 !important;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

.gallery {
  width: 110%;
  height: 110%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  pointer-events: none;
  opacity: .9;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(5, 1fr);
  transition: all 4s cubic-bezier(.16, 1, .3, 1);
  display: grid;
  position: absolute;
  inset: 0;
}

@media (max-width: 1200px) {
  .gallery {
    opacity: .4;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
}

.gallery__figure {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
}

.gallery__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.logo {
  max-width: 300px;
}

@media (max-width: 1200px) {
  .logo {
    max-width: 30vw;
  }
}

.logo img {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: block;
}

.has-perspective {
  perspective: 1000px;
  perspective-origin: 50%;
}

.headline {
  color: #fff;
  max-width: 28ch;
  font-kerning: normal;
  font-family: Bodoni Moda;
  font-size: calc(2.52vw + 20px);
  font-weight: 600;
  line-height: 1.1;
}

.linkedin-button {
  color: #2a3f4e;
  background-color: #fff;
  border-radius: 15px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: calc(.5vw + 16px);
  display: flex;
}

.linkedin-button:hover {
  color: #2a3f4e;
  background-color: #fff;
  transform: scale(1.05);
}

.linkedin-button img {
  width: calc(.5vw + 22px);
}

.overlay {
  opacity: .9;
  pointer-events: none;
  background: linear-gradient(to left, #0000, #2a3f4e);
  position: absolute;
  inset: 0;
}

.content {
  width: 100%;
  height: 100vh;
  z-index: 10;
  color: #bacfd7;
  opacity: 0;
  position: absolute;
  inset: 0;
  overflow-y: scroll;
}

.loaded .content {
  opacity: 1;
  transition: opacity 1s;
}

.content__header {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.content__inner {
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  gap: 5vh;
  padding: 2vw;
  display: flex;
}

@media (max-width: 1200px) {
  .content__inner {
    justify-content: start;
    gap: 12rem;
    padding: 6rem;
  }
}

.contact-details {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

@media (max-width: 1000px) {
  .contact-details {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 12rem;
  }
}

@media (min-width: 2000px) {
  .contact-details {
    margin-top: 0;
  }
}

.contact-details h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 3rem;
  font-size: 24px;
}

@media (max-width: 1200px) {
  .contact-details h3 {
    margin-bottom: 12px;
    font-size: 19px;
  }
}

.contact-details nav {
  margin: 6.5rem 0;
}

@media (min-width: 701px) and (max-width: 1200px) {
  .contact-details nav {
    margin: 12rem 0;
  }
}

@media (max-width: 700px) {
  .contact-details nav {
    margin: 12rem 0;
  }
}

.contact-details ul {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 30px;
  display: flex;
}

@media (max-width: 700px) {
  .contact-details ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin: 0;
  }
}

.contact-details li {
  margin: 0;
}

@media (max-width: 1200px) {
  .contact-details li {
    margin: 2rem 0;
  }
}

.contact-details li svg {
  width: 46px;
  fill: currentColor;
}

@media (max-width: 1200px) {
  .contact-details li svg {
    width: 37px;
  }
}

.contact-details h2 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: calc(.5vw + 12px);
}

.contact-details a {
  align-items: center;
  gap: 15px;
  font-size: calc(.5vw + 16px);
  display: flex;
}

.contact-details address {
  text-align: left;
  align-items: center;
  display: flex;
}

@media (max-width: 1200px) {
  .contact-details address {
    max-width: 35ch;
  }
}

.contact-details address a {
  font-size: 16px;
}

.contact-details address svg {
  width: 16px;
  opacity: .5;
}

@media (max-width: 1200px) {
  .contact-details address svg {
    width: 28px;
  }
}

.contact-details address span {
  margin-left: .8rem;
}

@media (max-width: 1200px) {
  .contact-details address span {
    margin-left: 3rem;
  }
}

/*# sourceMappingURL=index.69a9ceac.css.map */
