* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  background: $color-watermill-blue;
  font-size: calc(100vw / 1920 * 10);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

body {
  font-family: 'Raleway', serif;
  font-size: 21px;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  
  &:after {
    animation: grain 6s steps(10) infinite;
    background-image: url('../images/grain.png');
    background-repeat: repeat;
    content: '';
    height: 300%;
    left: -100%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: -100%;
    transition: opacity 0.4s ease;
    width: 300%;
    will-change: transform;
    z-index: 3;
  }

  // @media (max-width: $breakpoint) {
  //   height: 16px;
  // }

  .loaded &:after {
    opacity: 0.03;
  }
}

main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h1, h2, h3 {
  font-family: 'Bodoni Moda';
  color: $color-white;
  font-weight: 600;
  line-height: 1;
}

img {
  display: block;
  width: 100%;
}

ul {
  list-style: none;
}

// canvas {
//   height: 100%;
//   left: 0;
//   opacity: 0;
//   position: fixed;
//   top: 0;
//   transition: opacity 1s ease;
//   width: 100%;

//   .loaded & {
//     opacity: .3
//   }
// }

a {
  outline: none;
  text-decoration: none;
  color: $color-light-blue;
  transition: all .4s ease;

  &:hover,
  &:focus {
    outline: none;
    color: $color-white;
  }
}

svg {
  fill: currentColor;
}

@keyframes grain {
  0% { transform: translate(20%, -15%) }
  10% { transform: translate(-20%, -15%) }
  20% { transform: translate(20%, -5%) }
  30% { transform: translate(-20%, -5%) }
  40% { transform: translate(20%, 5%) }
  50% { transform: translate(-20%, 5%) }
  60% { transform: translate(20%, 15%) }
  70% { transform: translate(-20%, 15%) }
  80% { transform: translate(20%, 5%) }
  90% { transform: translate(-20%, 5%) }
  100% { transform: translate(20%, -5%) }
}
