.gallery {
    width: 110%;
    height: 110%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.9;
    transition: 4s cubic-bezier(0.16, 1, 0.3, 1);

    @media (max-width: 1200px) {
        opacity: 0.4;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
}

.gallery__figure {
    width:100%;
    height: 100%;
    position: relative;
    opacity: 0;

    // &:nth-child(1) {
    //     height: 80rem;
    //     width: 64rem;
    // }

    // &:nth-child(2) {
    //     height: 50rem;
    //     left: 71rem;
    //     top: 9rem;
    //     width: 68rem;
    // }

    // &:nth-child(3) {
    //     height: 79rem;
    //     left: 10rem;
    //     top: 87rem;
    //     width: 60rem;
    // }

    // &:nth-child(4) {
    //     height: 72rem;
    //     right: 0;
    //     top: 15rem;
    //     width: 46rem;
    // }

    // &:nth-child(5) {
    //     height: 60rem;
    //     right: 10rem;
    //     top: 93rem;
    //     width: 40rem;

    // }

    // &:nth-child(6) {
    //     height: 75rem;
    //     left: 5rem;
    //     top: 175rem;
    //     width: 57.5rem;
      
    // }

    // &:nth-child(7) {
    //     height: 60rem;
    //     right: 0;
    //     top: 159rem;
    //     width: 60rem;
    // }

    // &:nth-child(8) {

    //     height: 68rem;
    //     left: 79rem;
    //     top: 75rem;
    //     width: 54rem;
    // }

    // &:nth-child(9) {
    //     height: 45rem;
    //     left: 78rem;
    //     top: 170rem;
    //     width: 46rem;
 
    // }

    // &:nth-child(10) {
    //     height: 43rem;
    //     right: 0;
    //     top: 240rem;
    //     width: 40rem;
    // }

    // &:nth-child(11) {
    //     height: 54rem;
    //     left: 70rem;
    //     top: 235rem;
    //     width: 74rem;
    // }

    // &:nth-child(12) {
    //     left: 0;
    //     top: 256rem;
    //     height: 70rem;
    //     width: 50rem;
      
    // }

    // @media (max-width: 1200px) {
    //     &:nth-child(1) {
    //         height: 120rem;
    //         width: 100rem;
    //         top: 0;
    //     }

    //     &:nth-child(2) {
    //         height: 110rem;
    //         left: auto;
    //         right: 0;
    //         top: 30rem;
    //         width: 70rem;
    //     }

    //     &:nth-child(3) {
    //         height: 90rem;
    //         left: 12rem;
    //         top: 134rem;
    //         width: 89rem;
    //     }

    //     &:nth-child(4) {
    //         height: 100rem;
    //         right: 0;
    //         top: 170rem;
    //         width: 60rem;
    //     }

    //     &:nth-child(5) {
    //         height: 110rem;
    //         left: 0;
    //         right: auto;
    //         top: 240rem;
    //         width: 70rem;
    //     }

    //     &:nth-child(6) {
    //         height: 135rem;
    //         left: 95rem;
    //         top: 300rem;
    //         width: 85rem;
    //     }

    //     &:nth-child(7) {
    //         height: 110rem;
    //         left: 0;
    //         right: auto;
    //         top: 370rem;
    //         width: 80rem;
    //     }

    //     &:nth-child(8) {
    //         height: 100rem;
    //         left: auto;
    //         right: 0;
    //         top: 450rem;
    //         width: 80rem;
    //     }

    //     &:nth-child(9) {
    //         height: 100rem;
    //         left: 110rem;
    //         top: 570rem;
    //         width: 70rem;
    //     }

    //     &:nth-child(10) {
    //         height: 70rem;
    //         left: 20rem;
    //         right: auto;
    //         top: 510rem;
    //         width: 70rem;
 
    //     }

    //     &:nth-child(11) {
    //         height: 75rem;
    //         left: auto;
    //         right: 0;
    //         top: 690rem;
    //         width: 75rem;
    //     }

    //     &:nth-child(12) {
    //         left: 0;
    //         top: 630rem;
    //         height: 130rem;
    //         width: 90rem;
    //     }
    // }
}

.gallery__image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}